import styles from './ImageGalleryModal.module.scss';
import * as RadixDialog from '@radix-ui/react-dialog';
import { Gallery } from '../gallery';
import { IImage } from 'src/features/shared/contentful/types/IImage';
import Icons from 'src/features/shared/components/icons';
import { IconButton } from 'src/features/shared/components/icon-button';
import { useIntl } from 'src/domains/i18n';

interface ImageGalleryModalProps {
  open: boolean;
  images: IImage[];
  isMobile: boolean;
  onNextClick: () => void;
  onPreviousClick: () => void;
  onOpenChange: (open: boolean) => void;
}

export function ImageGalleryModal({
  open,
  images,
  isMobile,
  onNextClick,
  onPreviousClick,
  onOpenChange,
}: ImageGalleryModalProps) {
  const { formatMessage } = useIntl();
  return (
    <RadixDialog.Root open={open} onOpenChange={onOpenChange}>
      <RadixDialog.Portal>
        <RadixDialog.Overlay className={styles.overlay}>
          <RadixDialog.Content className={styles.content}>
            <Gallery
              items={images}
              isMobile={isMobile}
              onNextClick={onNextClick}
              onPreviousClick={onPreviousClick}
            />
            <div className={styles.closeButton}>
              <RadixDialog.Close asChild>
                <IconButton
                  icon={<Icons.CloseIcon />}
                  aria-label={formatMessage('club-detail.gallery.close')}
                />
              </RadixDialog.Close>
            </div>
          </RadixDialog.Content>
        </RadixDialog.Overlay>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
}
