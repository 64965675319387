import styles from './ClubsBodySectionView.module.scss';
import { useIntl } from 'domains/i18n';
import { Layout } from 'features/shared/components/layout';
import { Section } from 'features/shared/components/layout/section';
import { useEffect, useState } from 'react';
import { useClubsNearby } from 'src/features/checkout/hooks/use-clubs-nearby';
import { useClubsMap } from 'src/features/clubs-map-page/hooks/use-clubs-map';
import { ClubsOverviewList } from 'src/features/shared/components/sections/club-list/club-overview-list/ClubOverviewList';
import { H2 } from 'src/features/shared/components/typography';
import { Club } from 'src/features/shared/types/Club';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import * as Tabs from '../../../tabs';
import { ClubsOverviewMap } from '../club-overview-map';

export interface IClubsBodySectionViewProps {
  clubs: Club.MapCard[];
  city: string;
}

function getTabValue(index: number) {
  return `tab-${index}`;
}

export function ClubsBodySectionView({
  clubs,
  city,
}: IClubsBodySectionViewProps) {
  const { formatMessage } = useIntl();
  const [enableUserLocation, setEnableUserLocation] = useState(false);
  const [activeTab, setActiveTab] = useState(getTabValue(0));

  const clubsNearby = useClubsNearby({
    clubs,
    enabled: enableUserLocation,
  });

  const map = useClubsMap({
    clubs: clubs,
    searchResults: clubsNearby.results,
    searchNearby: enableUserLocation,
  });

  const title = formatMessage(
    clubs.length === 1
      ? 'clubs.club-list.title.search.singular'
      : 'clubs.club-list.title.search.plural',
    { count: clubs.length, city },
  );

  useEffect(() => {
    pushGA4Event(GA4_EVENTS.trackEvent, {
      event_name:
        activeTab === getTabValue(0)
          ? 'view_mapsection_list'
          : 'view_mapsection_map',
    });
  }, [activeTab]);

  return (
    <Section>
      <Layout.Container>
        <Layout.Row>
          <Layout.Col className={styles.colCentered} columns="xs:12">
            <H2>{title}</H2>
          </Layout.Col>
        </Layout.Row>

        <Tabs.Root value={activeTab} onValueChange={setActiveTab}>
          <Layout.Row className={styles.tabRow}>
            <Layout.Col>
              <Tabs.List
                variant={'default'}
                value={activeTab}
                items={[
                  {
                    value: getTabValue(0),
                    label: formatMessage('map.list-view'),
                  },
                  {
                    value: getTabValue(1),
                    label: formatMessage('map.map-view'),
                  },
                ]}
                onValueChange={setActiveTab}
              />
            </Layout.Col>
          </Layout.Row>

          {/* List content */}
          <Tabs.Content
            className="TabsContent"
            value={getTabValue(0)}
            tabIndex={-1}
          >
            <ClubsOverviewList clubs={clubs} />
          </Tabs.Content>

          {/* Map content */}
          <Tabs.Content
            className="TabsContent"
            value={getTabValue(1)}
            tabIndex={-1}
          >
            <ClubsOverviewMap
              clubs={map.listResults}
              markers={map.markersData}
              geolocation={map.geolocation}
              onEnableUserLocation={() => setEnableUserLocation(true)}
            />
          </Tabs.Content>
        </Tabs.Root>
      </Layout.Container>
    </Section>
  );
}
