import styles from './MobileInfoCard.module.scss';
import { useIntl } from '@domains/i18n';
import classNames from 'classnames';
import { CHECKOUT_URL } from 'src/features/checkout/util/constants';
import { Link } from 'src/features/shared/components/link';
import { ContentfulImage } from 'src/features/shared/components/contentful-image/ContentfulImage';
import {
  Body,
  FontColor,
  FontWeight,
  H5,
  Note,
  Price,
} from 'src/features/shared/components/typography';
import { useFormattedPrice } from 'src/features/shared/hooks/use-formatted-price';
import { Club } from 'src/features/shared/types/Club';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import { Button } from 'src/features/shared/components/button';
import Icons from 'src/features/shared/components/icons';
import { ButtonVariant } from 'src/features/shared/types/enums/ButtonVariant';

interface Props {
  club: Club.MapCard;
  onCloseClick: () => void;
}

export const MobileInfoCard = ({ club, onCloseClick }: Props) => {
  const { formatMessage } = useIntl();
  const fromPriceBySlug = club.priceFrom;

  const fromPrice = fromPriceBySlug || club.priceFrom;
  const hasFromPrice = Boolean(fromPrice);
  const checkoutLink = `/${CHECKOUT_URL}?clubId=${club.pgClubId}`;

  const footerClassName = classNames([
    styles.footerContent,
    {
      [styles.noBorder]: !hasFromPrice,
    },
  ]);

  const trackEventGA4 = (eventName: string) => {
    pushGA4Event(GA4_EVENTS.trackEvent, { event_name: eventName });
  };

  const { integer: priceEuros, fractionWithDecimal: priceCents } =
    useFormattedPrice(fromPrice || '');
  const distance =
    club.geoDistanceFromSearch &&
    `${Math.round(club.geoDistanceFromSearch / 1000)} km`;

  return (
    <div className={styles.container}>
      <Button
        variant={ButtonVariant.Ghost}
        className={styles.closeButton}
        onClick={onCloseClick}
      >
        <Icons.CloseIcon></Icons.CloseIcon>
      </Button>
      <div className={styles.wrapper}>
        {club.images?.[0].src && (
          <Link href={club.slug} className={styles.imageLink}>
            <ContentfulImage
              src={club.images?.[0].src}
              alt={club.name}
              width={134}
              height={181}
            />
          </Link>
        )}
        <div className={styles.content}>
          <div className={styles.title}>
            <Link href={club.slug}>
              <H5 component="div">{club.name}</H5>
            </Link>
            {distance && (
              <div className={styles.geoDistance}>
                <Note color={FontColor.Neutral02}>{distance}</Note>
              </div>
            )}
          </div>
          <Body
            className={styles.address}
            component="div"
            color={FontColor.Neutral02}
          >
            {club.address}, {club.city}
          </Body>
          <div className={footerClassName}>
            {hasFromPrice && (
              <div className={styles.cta}>
                <Body component="span">{formatMessage('price.from')} </Body>
                <Price className={styles.price} component="span">
                  {priceEuros}{' '}
                </Price>
                {priceCents && (
                  <Note
                    className={styles.subscript}
                    component="span"
                    fontWeight={FontWeight.Bold}
                  >
                    {priceCents}
                  </Note>
                )}
                <Body component="span">
                  {formatMessage(
                    'checkout.select-club.club-list.from-duration',
                  )}
                </Body>
              </div>
            )}
          </div>
          <div className={styles.buttonsContainer}>
            <Link
              href={`/${club.slug}`}
              onClick={() => trackEventGA4('click_cta_club_details')}
              className={styles.button}
            >
              {formatMessage('clubs.map.open-club')}
            </Link>
            <Link
              onClick={() => trackEventGA4('click_cta_membership')}
              href={checkoutLink}
              className={classNames(styles.button, styles.primary)}
              isHtmlTag
            >
              {formatMessage('become-member')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
