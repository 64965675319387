import styles from './index.module.scss';
import { useIntl } from '@domains/i18n';
import { Anchor } from '@features/club-page/components/club-details/anchor-links/anchor';
import { JoinButton } from '@features/shared/components/join-button';
import { GA4_EVENTS, pushGA4Event } from '@features/shared/utils/ga4';
import { OpeningHoursTable } from 'src/features/shared/components/opening-hours-table';
import { H3 } from 'src/features/shared/components/typography';
import { Club } from 'src/features/shared/types/Club';
import { ButtonVariant } from 'src/features/shared/types/enums/ButtonVariant';
import { Price } from './price';

interface IOpeningHoursProps {
  club: Club.Details;
  isPanel?: boolean;
}

export const OpeningHours = ({ club, isPanel }: IOpeningHoursProps) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.container}>
      <Anchor title={formatMessage('club-detail.opening-hours.title')} />
      <H3 forceMobileView={isPanel}>
        {formatMessage('club-detail.opening-hours.title')}
      </H3>

      <OpeningHoursTable
        openingDate={club.openingDate}
        openingHours={club.openingHours}
        openingHoursExceptions={club.openingHoursExceptions}
      />

      <hr className={styles.horizontalRule} />

      <div className={styles.footer}>
        <div>
          <Price pgClubId={club.pgClubId} />
        </div>
        {!isPanel && (
          <JoinButton
            clubId={club.pgClubId}
            dataTestId={isPanel ? 'club-side-panel_cta_2' : 'club-page_cta_2'}
            onClick={() =>
              pushGA4Event(GA4_EVENTS.trackEvent, {
                event_name: 'click_cta_openinghours',
              })
            }
            variant={ButtonVariant.Secondary}
          />
        )}
      </div>
    </div>
  );
};
