import styles from './TextImage.module.scss';
import { SectionHeader } from '../../section-header';
import classNames from 'classnames';
import { Anchor } from '@features/club-page/components/club-details/anchor-links/anchor';
import { ContentfulImage } from '@features/shared/components/contentful-image';
import { Col, Container, Row } from '@features/shared/components/layout';
import { Section } from '@features/shared/components/layout/section';
import { useRichTextRenderer } from 'src/features/shared/hooks/useRichTextRenderer';
import { SectionButton } from 'src/features/shared/components/sections/section-button/SectionButton';
import { ITextImageSection } from 'src/features/shared/contentful/types/ITextImage';

export interface ITextImageProps {
  item: ITextImageSection;
  stickToTop?: boolean;
  stickToBottom?: boolean;
}

export const TextImage = ({
  item,
  stickToBottom,
  stickToTop,
}: ITextImageProps) => {
  const { renderSectionDescription } = useRichTextRenderer();

  const {
    title,
    description,
    backgroundColor,
    image,
    anchorLinkTitle,
    imageAlignment,
    secondaryButton,
    primaryButton,
    primaryButtonDisplayAs,
    secondaryButtonDisplayAs,
    roundedCorners,
  } = item;

  const isAlignedRight = imageAlignment === 'Right';
  const isAlignedLeft = imageAlignment === 'Left';

  return (
    <Section
      backgroundColor={backgroundColor}
      hasNoBottomMargin={stickToBottom}
      hasImage
      data-testid="text-image-section"
    >
      {anchorLinkTitle && <Anchor title={anchorLinkTitle} />}

      <Container stickToBottom={stickToBottom} stickToTop={stickToTop}>
        <Row reversed={isAlignedRight} className={styles.row}>
          <Col
            className={classNames(styles.imageCol)}
            columns="md:6"
            offset={isAlignedRight ? 'md:1' : undefined}
          >
            {image && (
              <ContentfulImage
                className={classNames(styles.image, {
                  [styles.rounded]: roundedCorners,
                })}
                src={image.src}
                alt={image.alt}
                width={600}
                height={600}
                style={{ width: '100%', height: 'auto' }}
              />
            )}
          </Col>

          <Col
            columns="md:5"
            className={classNames(styles.content, {
              [styles.isLeft]: isAlignedLeft,
              [styles.isRight]: isAlignedRight,
            })}
          >
            <div>
              <SectionHeader title={title} />
              {description
                ? renderSectionDescription(description, styles.description)
                : null}
              <div className={styles.buttonsWrapper}>
                {primaryButton ? (
                  <SectionButton
                    link={primaryButton}
                    label={primaryButton.label}
                    eventName="click_primary_cta_imagesection"
                    displayAs={primaryButtonDisplayAs}
                  />
                ) : null}
                {secondaryButton ? (
                  <SectionButton
                    link={secondaryButton}
                    label={secondaryButton.label}
                    eventName="click_secondary_cta_imagesection"
                    displayAs={secondaryButtonDisplayAs}
                  />
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};
