type Breakpoints = 'sm' | 'md' | 'lg' | 'xl';

interface BreakPointSizes {
  sm: number;
  md: number;
  lg: number;
  xl: null;
}

const breakPointSizes: BreakPointSizes = {
  sm: 375,
  md: 768,
  lg: 1024,
  xl: null,
};

export function getImageSizes(
  sizes: Partial<Record<Breakpoints, string>>,
): string {
  const formattedSizes = Object.entries(sizes).map(([key, size], index) => {
    const maxWidth = breakPointSizes[key as Breakpoints];
    const isLastSize = index === Object.keys(sizes).length - 1;

    return !isLastSize && maxWidth
      ? `(max-width: ${maxWidth}px) ${size}`
      : size;
  });

  return formattedSizes.join(', ');
}
