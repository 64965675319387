import styles from './ClubOverviewList.module.scss';
import { Layout } from 'features/shared/components/layout';
import { useIntl } from 'src/domains/i18n';
import { ClubCard } from 'src/features/clubs-map-page/components/club-card';
import { Club } from 'src/features/shared/types/Club';

interface ClubsOverviewListProps {
  clubs: Club.MapCard[];
}

export function ClubsOverviewList({ clubs }: ClubsOverviewListProps) {
  const { formatMessage } = useIntl();
  return (
    <Layout.Row component="ul" className={styles.row}>
      {clubs.map((club: Club.MapCard) => {
        return (
          <Layout.Col
            component="li"
            key={club.id}
            columns="xs:12 sm:6 md:4"
            className={styles.col}
          >
            <ClubCard
              club={club}
              className={styles.card}
              primaryButtonStyle="primary"
              primaryButtonLabel={formatMessage('become-member')}
            />
          </Layout.Col>
        );
      })}
    </Layout.Row>
  );
}
