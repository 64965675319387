import { ComponentProps, useEffect, useRef } from 'react';
import { ContentfulImage } from '../contentful-image';
import styles from './index.module.scss';

export interface ICarouselImageProps
  extends ComponentProps<typeof ContentfulImage> {
  onUpdateHeight?: (height: number) => void;
}

export function CarouselImage({
  onUpdateHeight,
  ...props
}: ICarouselImageProps) {
  const imageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateImageHeight = () => {
      if (imageRef?.current?.offsetHeight != null) {
        onUpdateHeight?.(imageRef?.current?.offsetHeight);
      }
    };

    const onResize = () => updateImageHeight();
    const onLoad = () => updateImageHeight();

    if (onUpdateHeight) {
      window.addEventListener('resize', onResize);
      imageRef.current?.addEventListener('load', onLoad);
    }

    return () => {
      window.removeEventListener('resize', onResize);
      imageRef.current?.removeEventListener('load', onLoad);
    };
  }, [imageRef.current, onUpdateHeight]);

  return (
    <div className={styles.image} ref={imageRef}>
      <ContentfulImage fill {...props} />
    </div>
  );
}
