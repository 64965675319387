'use client';
import styles from './index.module.scss';
import { useIntl } from '@domains/i18n';
import { useAnchorLinksTags } from '@features/club-page/utils/use-anchor-links-tags';
import { useSpecialists } from '@features/specialists/hooks/use-specialists';
import useBreakpoints from '@utils/hooks/use-breakpoints';
import ContentBlocksSectionView from '@views/sections/content-blocks';
import { ClubsNearbyView } from 'src/features/shared/components/clubs-nearby';
import { LinkList } from 'src/features/shared/components/sections/link-list/LinkList';
import { IClubContentPage } from 'src/features/shared/contentful/types/IClubContentPage';
import { Club } from 'src/features/shared/types/Club';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import { CONTENT_TYPES } from '../../../../contentful/constants';
import { getAllClubImages } from '../utils/get-all-club-images';
import { BreadcrumbNavigation } from './breadcrumb-navigation';
import { ClubDetails } from './club-details/ClubDetails';
import { Contact } from './contact';
import { useEffect, useState } from 'react';
import { ImageGalleryModal } from './image-gallery-modal';

interface IClubPageProps {
  club: Club.Details;
  isPanel?: boolean;
  page: IClubContentPage;
}

export const ClubPage = ({ club, isPanel = false, page }: IClubPageProps) => {
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const { formatMessage } = useIntl();
  const [galleryModalOpen, setGallerModalOpen] = useState(false);

  const { specialists } = useSpecialists(club.pgClubId);
  const { tags } = useAnchorLinksTags(
    page?.contentBlocks,
    specialists?.length !== 0,
  );

  const { images: clubImages, isSliderOrCarouselActive } = getAllClubImages(
    page?.clubHeaderMedia,
    page?.contentBlocks,
  );

  useEffect(() => {
    pushGA4Event(GA4_EVENTS.pageData, {
      page_type: 'Club',
    });
  }, []);

  const onClickGallery = () => {
    setGallerModalOpen(true);
    pushGA4Event(GA4_EVENTS.trackEvent, { event_name: 'click_all_pictures' });
  };

  const onGalleryNextClick = () => {
    pushGA4Event(GA4_EVENTS.trackEvent, {
      event_name: 'click_club_pictures_right',
    });
  };

  const onGalleryPreviousClick = () => {
    pushGA4Event(GA4_EVENTS.trackEvent, {
      event_name: 'click_club_pictures_left',
    });
  };

  const onGalleryModalOpenChange = (open: boolean) => {
    setGallerModalOpen(open);
    if (!open) {
      pushGA4Event(GA4_EVENTS.trackEvent, {
        event_name: 'close_club_pictures',
      });
    }
  };

  const isMobile = currentBreakpoint === breakpoints.xs.name;

  const geoLocation = {
    latitude: club.latitude,
    longitude: club.longitude,
  };

  const clubContactDetails = {
    address: club.address,
    postalCode: club.postalCode,
    city: club.city,
    emailAddress: club.email,
    phoneNumber: club.phoneNumber,
  };

  const panelAllowedContentBlocks = [
    CONTENT_TYPES.CLUB_OFFERS,
    CONTENT_TYPES.GENERIC_CONTENT_SECTION,
    CONTENT_TYPES.QUOTE_SECTION,
    CONTENT_TYPES.IMAGE_SLIDER,
  ];

  return (
    <div className={isPanel ? styles.clubPage : ''}>
      {!isPanel && <BreadcrumbNavigation club={club} />}

      <ImageGalleryModal
        open={galleryModalOpen}
        onOpenChange={onGalleryModalOpenChange}
        images={clubImages}
        isMobile={isMobile}
        onNextClick={onGalleryNextClick}
        onPreviousClick={onGalleryPreviousClick}
      />

      <ClubDetails
        club={club}
        page={page}
        isPanel={isPanel}
        tags={tags}
        onClickGallery={isSliderOrCarouselActive ? onClickGallery : null}
        isMobile={isMobile}
        hasClubOffers={
          page.contentBlocks?.some(
            (p) => p.type === CONTENT_TYPES.CLUB_OFFERS,
          ) || false
        }
      />

      {page.contentBlocks ? (
        <ContentBlocksSectionView
          page={page}
          contentBlocks={
            isPanel
              ? page.contentBlocks.filter((content) =>
                  panelAllowedContentBlocks.includes(content.type || ''),
                )
              : page.contentBlocks
          }
          isPanel={isPanel}
          club={club}
          onClickImageSlider={() => setGallerModalOpen(true)}
          onClickAccessibleLabel={formatMessage(
            'club-detail.gallery.view-all-images',
          )}
          specialists={specialists}
        />
      ) : null}

      <Contact
        isMobile={isMobile}
        isPanel={isPanel}
        clubLocation={geoLocation}
        clubDetails={clubContactDetails}
      />
      {!isPanel && (
        <ClubsNearbyView geoLocation={geoLocation} clubName={club.name} />
      )}
      {!isPanel && page.seoLinks && (
        <LinkList
          title={page.seoLinks.title}
          menuItems={page.seoLinks.menuItems}
        />
      )}
    </div>
  );
};
