import { useIntl } from '@domains/i18n';
import styles from './index.module.scss';
import * as Carousel from '@features/shared/components/carousel';
import { ClubNearbyCard } from '@features/shared/components/clubs-nearby/club-nearby-card';
import { DescriptionTypography } from '@features/shared/components/description-typography';
import { Layout } from '@features/shared/components/layout';
import { Section } from '@features/shared/components/layout/section';
import { IGeolocation } from '@models/IGeolocation';
import { addDistanceToClubs } from '@utils/add-distance-to-clubs';
import NextLink from 'next/link';
import { ClubPageSectionHeading } from 'src/features/shared/components/typography';
import { Club } from 'src/features/shared/types/Club';
import getClosestClubs from 'src/utils/get-closest-clubs';
import { useClubs } from '../../hooks/clubs/use-clubs';
import { GA4_EVENTS, pushGA4Event } from '../../utils/ga4';

interface IClubsNearbyView {
  geoLocation: IGeolocation;
  clubName: string;
}

const settings = {
  slidesToShow: 3.7,
  responsive: [
    {
      breakpoint: 1439,
      settings: {
        slidesToShow: 3.7,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 3.2,
      },
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 3.2,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.2,
        centerPadding: '16px',
        centerMode: true,
      },
    },
  ],
};

export function ClubsNearbyView({ geoLocation, clubName }: IClubsNearbyView) {
  const { clubs } = useClubs();
  const { formatMessage } = useIntl();

  const description = formatMessage('club-detail.clubs-nearby.subtitle', {
    clubName,
    clubsCount: clubs?.length,
  });

  const clubsWithGeodistance = addDistanceToClubs<Club.MapCard>(
    clubs ?? [],
    geoLocation,
    'geoDistanceFromSearch',
  );
  const nearbyClubs = getClosestClubs(
    clubsWithGeodistance,
    6,
    'geoDistanceFromSearch',
  );

  if (!nearbyClubs) return null;

  const items = nearbyClubs.map((club) => {
    return {
      ...club,
      image: club.images?.[0],
      title: club.name,
      description: `${club.address}, ${club.city}`,
      distance: club.geoDistanceFromSearch
        ? `${Math.round(club.geoDistanceFromSearch / 1000)} km`
        : undefined,
      link: {
        href: `/${club.slug}`,
      },
    };
  });

  if (!items.length) return null;

  return (
    <Section>
      <Layout.Container>
        <Layout.Row>
          <Layout.Col columns="sm:10 md:8">
            <ClubPageSectionHeading>
              {formatMessage('club-detail.clubs-nearby.title')}
            </ClubPageSectionHeading>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row>
          <Layout.Col columns="xs:12 md:6 lg:5">
            <DescriptionTypography>
              <p>{description}</p>
            </DescriptionTypography>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row>
          <Layout.Col columns="xs:12">
            <Carousel.Root
              bottomButtons
              settings={settings}
              onNextClick={() => {
                pushGA4Event(GA4_EVENTS.trackEvent, {
                  event_name: 'show_more_clubs',
                });
              }}
            >
              {items.map((item) => (
                <Carousel.Item
                  key={item.description}
                  className={styles.carouselItem}
                >
                  {item.link.href && (
                    <NextLink
                      href={item.link.href}
                      onClick={() => {
                        pushGA4Event(GA4_EVENTS.trackEvent, {
                          event_name: 'click_club_nearby',
                        });
                      }}
                    >
                      <ClubNearbyCard
                        image={item.image}
                        title={item.title}
                        description={item.description}
                        distance={item.distance}
                      />
                    </NextLink>
                  )}
                </Carousel.Item>
              ))}
            </Carousel.Root>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </Section>
  );
}
