import { useIntl } from '@domains/i18n';
import styles from '@features/club-page/components/club-details/opening-hours/index.module.scss';
import { Small } from 'src/features/shared/components/typography';
import { useClub } from 'src/features/shared/hooks/clubs/use-club';

interface Props {
  pgClubId: number;
}

export const Price = ({ pgClubId }: Props) => {
  const { formatMessage, formatNumberToParts } = useIntl();
  const { club } = useClub(pgClubId);

  const fromPrice = club?.priceFrom;

  const fromPriceParts: [{ value: string; type: string }] =
    fromPrice &&
    formatNumberToParts(fromPrice, {
      style: 'currency',
      currency: 'EUR',
    });
  const fraction = fromPriceParts?.find(
    (item) => item.type === 'fraction',
  )?.value;
  const formattedPrice = fromPriceParts
    ?.filter((item) => item.type !== 'fraction')
    .map(({ value }) => value)
    .join('');

  if (!formattedPrice) {
    return null;
  }

  return (
    <div className={styles.priceContainer}>
      <div>
        <p className={styles.startingFrom}>{formatMessage('price.from')}</p>
        <p className={styles.price}>
          {formattedPrice}
          {<sup className={styles.fraction}>{fraction}</sup>}
        </p>
      </div>
      <Small className={styles.priceDescription}>
        {formatMessage('funnel.commitmentPeriod.priceDescription.4-week')}
      </Small>
    </div>
  );
};
