import { useClubs } from './use-clubs';

export function useClub(pgClubId: number | undefined) {
  const { clubs, ...rest } = useClubs();
  const club = clubs?.find((club) => club.pgClubId === pgClubId);

  return {
    club,
    ...rest,
  };
}
