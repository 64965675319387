import classNames from 'classnames';
import styles from './IconButton.module.scss';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

export interface IIconButtonProps extends ComponentPropsWithoutRef<'button'> {
  icon: JSX.Element;
}

export const IconButton = forwardRef<HTMLButtonElement, IIconButtonProps>(
  ({ icon, onClick, className, ...props }, ref) => (
    <button
      ref={ref}
      className={classNames(styles.iconButton, className)}
      onClick={onClick}
      type="button"
      {...props}
    >
      {icon}
    </button>
  ),
);

IconButton.displayName = 'IconButton';
