import styles from './SocialCardsMobile.module.scss';
import classNames from 'classnames';
import { ISocialSectionItem } from 'src/features/shared/contentful/types/ISocialSection';
import { SocialCardsItem } from '../social-cards-item';
import * as Carousel from 'src/features/shared/components/carousel';

interface SocialCardsProps {
  items: ISocialSectionItem[];
}

const settings = {
  slidesToShow: 1.2,
  responsive: [
    {
      breakpoint: 1439,
      settings: {
        slidesToShow: 3.7,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.2,
        centerPadding: '16px',
        centerMode: true,
      },
    },
  ],
};

export function SocialCardsMobile({ items }: SocialCardsProps) {
  return (
    <div className={classNames(styles.container, styles.mobile)}>
      <Carousel.Root settings={settings} className={styles.carousel}>
        {items.map((item, index) => (
          <Carousel.Item key={index} className={styles.slide}>
            <SocialCardsItem className={styles.card} {...item} />
          </Carousel.Item>
        ))}
      </Carousel.Root>
    </div>
  );
}
