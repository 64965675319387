import styles from './ImageBanner.module.scss';
import { Button } from '@features/shared/components/button';
import { ButtonType } from '@features/shared/types/enums/ButtonType';
import { ButtonVariant } from '@features/shared/types/enums/ButtonVariant';
import { ContentfulImage } from 'src/features/shared/components/contentful-image';

export interface IImageBannerProps {
  imageUrl: string;
  alt: string;
  buttonLabel?: string;
  onClickButton?: () => void;
  buttonVariant?: ButtonVariant;
}

export const ImageBanner = ({
  imageUrl,
  buttonLabel,
  onClickButton,
  buttonVariant,
  alt,
}: IImageBannerProps) => (
  <div className={styles.container}>
    <ContentfulImage
      src={imageUrl}
      alt={alt}
      loading="lazy"
      className={styles.image}
      fill
      sizes="(max-width: 768px) 100vw, 33vw"
    />
    {onClickButton && buttonLabel && (
      <Button
        type={ButtonType.Button}
        variant={buttonVariant}
        onClick={onClickButton}
      >
        {buttonLabel}
      </Button>
    )}
  </div>
);
