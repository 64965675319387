import { ButtonVariant } from 'src/features/shared/types/enums/ButtonVariant';
import { Button } from '../../button';
import Icons from '../../icons';
import { Section, Col, Container, Row } from '../../layout';
import { H2 } from '../../typography';
import styles from './SocialSection.module.scss';
import { SocialCardsMobile } from './components/social-cards-mobile';
import { SocialCardsDesktop } from './components/social-cards-desktop';
import { ISocialSection } from 'src/features/shared/contentful/types/ISocialSection';
import useBreakpoints from 'src/utils/hooks/use-breakpoints';
import theme from 'src/config/theme';

export function SocialSection({
  title,
  items,
  backgroundColor,
}: ISocialSection) {
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const isMobile =
    currentBreakpoint === breakpoints.xs.name ||
    currentBreakpoint === breakpoints.sm.name;

  const buttons = [
    {
      href: theme.socialLink.instagram,
      label: `@${theme.social.instagram}`,
      icon: <Icons.Instagram />,
    },
    {
      href: theme.socialLink.facebook,
      label: theme.social.facebook,
      icon: <Icons.Facebook />,
    },
    {
      href: theme.socialLink.tiktok,
      label: `@${theme.social.tiktok}`,
      icon: <Icons.Tiktok />,
    },
  ];

  return (
    <Section backgroundColor={backgroundColor}>
      <Container>
        <Row>
          <Col>
            <div className={styles.container}>
              <H2 className={styles.title}>{title}</H2>
              {isMobile ? (
                <SocialCardsMobile items={items} />
              ) : (
                <SocialCardsDesktop items={items} />
              )}
              <div className={styles.buttonsContainer}>
                {buttons.map(({ href, label, icon }) => (
                  <Button
                    key={href}
                    href={href}
                    variant={ButtonVariant.Inverted}
                    icon={icon}
                    openInNewTab
                    className={styles.button}
                  >
                    {label}
                  </Button>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  );
}
