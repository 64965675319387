import * as DialogPrimitive from '@radix-ui/react-dialog';
import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'src/domains/i18n';
import { IconButton } from '../icon-button';
import Icons from '../icons';
import { H3 } from '../typography';
import styles from './Dialog.module.scss';

export const Root = DialogPrimitive.Root;

export const Trigger = DialogPrimitive.Trigger;

interface DialogContentProps extends DialogPrimitive.DialogContentProps {}
export const Content = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  DialogContentProps
>(({ children, className, title, ...otherProps }, forwardedRef) => {
  const { formatMessage } = useIntl();
  return (
    <DialogPrimitive.Portal>
      <DialogPrimitive.Overlay className={styles.overlay} />
      <DialogPrimitive.Content
        className={classNames(styles.content, className)}
        ref={forwardedRef}
        {...otherProps}
      >
        <div className={styles.contentInner}>
          <div className={styles.header}>
            <DialogPrimitive.Title className={styles.title} asChild>
              <H3 component="h2">{title}</H3>
            </DialogPrimitive.Title>
            <DialogPrimitive.Close asChild>
              <IconButton
                icon={<Icons.CloseIcon />}
                aria-label={formatMessage('club-detail.gallery.close')}
                data-testid="sheet-close-button"
                className={styles.closeButton}
              />
            </DialogPrimitive.Close>
          </div>
          {children}
        </div>
      </DialogPrimitive.Content>
    </DialogPrimitive.Portal>
  );
});

Content.displayName = 'Dialog.Content';
