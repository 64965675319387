import styles from './index.module.scss';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import ClusterIcon from '@assets/markers/marker-cluster.svg';
import { ComponentProps } from 'react';
import classNames from 'classnames';

interface IClusterProps extends ComponentProps<typeof AdvancedMarker> {
  count: number;
}

export const Cluster = ({ count, className, ...props }: IClusterProps) => (
  <AdvancedMarker {...props}>
    <div className={classNames(styles.container, className)}>
      <ClusterIcon width={53} height={69} />
      <span>{count}</span>
    </div>
  </AdvancedMarker>
);
