import { useRef, useState } from 'react';
import styles from './SocialCardsDesktop.module.scss';
import { useInViewport } from 'src/features/shared/hooks/use-in-viewport';
import classNames from 'classnames';
import { ISocialSectionItem } from 'src/features/shared/contentful/types/ISocialSection';
import { SocialCardsItem } from '../social-cards-item';

interface SocialCardsProps {
  items: ISocialSectionItem[];
}

export function SocialCardsDesktop({ items }: SocialCardsProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);

  useInViewport(ref, {
    onEnterViewport: () => {
      setExpanded(true);
    },
  });

  return (
    <div
      className={classNames(styles.container, {
        [styles.expanded]: expanded,
      })}
      ref={ref}
    >
      {items.map((item, index) => (
        <SocialCardsItem key={index} {...item} className={styles.card} />
      ))}
    </div>
  );
}
