import theme from 'config/theme';
import styles from './index.module.scss';
import { Link } from 'src/features/shared/components/link';
import classNames from 'classnames';

const appStore = {
  src: '/static/images/shared/app-store-dark.svg',
  alt: 'app-store',
};

const playStore = {
  src: '/static/images/shared/google-play.svg',
  alt: 'play-store',
};

export const AppButtons = () => {
  return (
    <div className={styles.buttons}>
      <Link openInNewTab href={theme.app.appStore} className={styles.button}>
        <img
          className={classNames(styles.marginRightM, styles.appStoreImage)}
          src={appStore.src}
          alt={appStore.alt}
        />
      </Link>
      <Link openInNewTab href={theme.app.playStore} className={styles.button}>
        {/* // eslint-disable-next-line @next/next/no-img-element */}
        <img
          className={styles.playStoreImage}
          src={playStore.src}
          alt={playStore.alt}
        />
      </Link>
    </div>
  );
};
