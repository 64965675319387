import NextImage from 'next/image';

export interface ILogoSmallProps {
  className?: string;
}

export const LogoSmall = ({ className }: ILogoSmallProps) => (
  <NextImage
    className={className}
    alt="SportCity logo"
    src="/static/images/sportcity/illustrations/fallback-thumb.svg"
    width={64}
    height={70}
  />
);
