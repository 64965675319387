import styles from './ClubListSection.module.scss';
import { useMemo } from 'react';
import {
  Col,
  Container,
  Row,
  Section,
} from 'src/features/shared/components/layout';
import { Loading } from 'src/features/shared/components/loading';
import { ClubsBodySectionView } from 'src/features/shared/components/sections/club-list/clubs-body-section-view';
import { IClubListSection } from 'src/features/shared/contentful/types/IClubListSection';
import { useClubs } from 'src/features/shared/hooks/clubs/use-clubs';

export function ClubListSection({ city, visibleClubs }: IClubListSection) {
  const { clubs = [], isLoading } = useClubs();

  const clubsToShow = useMemo(() => {
    if (!visibleClubs) {
      return clubs?.filter((club) => club.city === city) ?? [];
    }

    return (
      clubs?.filter(({ pgClubId }) => visibleClubs.includes(pgClubId)) ?? []
    );
  }, [clubs, visibleClubs, city]);

  if (clubs === undefined && !isLoading) {
    console.error("Couldn't fetch clubs");
    return null;
  }

  if (isLoading) {
    return (
      <Section>
        <Container>
          <Row>
            <Col columns="xs:12" className={styles.loadingCol}>
              <Loading />
            </Col>
          </Row>
        </Container>
      </Section>
    );
  }

  return <ClubsBodySectionView city={city} clubs={clubsToShow} />;
}
