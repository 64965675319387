import styles from './HighlightedAttributes.module.scss';
import { useIntl } from '@domains/i18n';
import { IClubAttribute } from '@features/shared/contentful/types/IClubAttribute';
import { Link } from '@features/shared/components/link';
import { Attribute } from 'src/features/shared/components/attribute';

interface IHighlightedAttributesProps {
  attributes: IClubAttribute[];
  hasClubOffers?: boolean;
}

export const HighlightedAttributes = ({
  attributes,
  hasClubOffers,
}: IHighlightedAttributesProps) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.attributeList}>
      {attributes.map((attribute) => (
        <Attribute
          key={attribute.name}
          title={attribute.title}
          icon={attribute.icon}
        />
      ))}

      {hasClubOffers && (
        <Link className={styles.link} href="#aanbod" isHtmlTag isSmall>
          <span className={styles.disableUnderline}>+</span>
          {formatMessage('club-detail.about.offersCta')}
        </Link>
      )}
    </div>
  );
};
