import { IBentoboxSection } from 'src/features/shared/contentful/types/IBentoboxSection';
import { Layout, Section } from '@features/shared/components/layout';
import styles from './BentoBoxSection.module.scss';
import { ContentfulImage } from '../../contentful-image';
import { H2 } from '../../typography';
import { useRichTextRenderer } from 'src/features/shared/hooks/useRichTextRenderer';
import { RichText } from '../../rich-text';
import { Button } from '../../button';
import { ButtonVariant } from 'src/features/shared/types/enums/ButtonVariant';
import { IVideo } from 'src/features/shared/contentful/types/IVideo';
import { BLOCKS } from '@contentful/rich-text-types';
import Icons from '../../icons';
import { IImage } from 'src/features/shared/contentful/types/IImage';

export function BentoboxSection({
  title,
  description,
  items,
  button,
}: IBentoboxSection) {
  const { renderRichText } = useRichTextRenderer(undefined, false, {
    renderNode: {
      [BLOCKS.LIST_ITEM]: (node, children) => {
        return (
          <li className={styles.listItem}>
            <Icons.Checkmark />
            {children}
          </li>
        );
      },
    },
  });

  function renderItem(item: IImage | IVideo) {
    if ('url' in item) {
      return (
        <video
          src={item.url}
          title={item.title}
          className={styles.video}
          playsInline
          autoPlay
          muted
          loop
        />
      );
    }

    return (
      <ContentfulImage
        src={item.src}
        alt={item.alt}
        fill
        className={styles.image}
      />
    );
  }

  return (
    <Section>
      <Layout.Container>
        <Layout.Row>
          <Layout.Col>
            <div className={styles.sectionGrid}>
              <div className={styles.content}>
                <H2 className={styles.title}>{title}</H2>
                <RichText className={styles.description} isLight>
                  {description && renderRichText(description)}
                </RichText>

                {button && (
                  <Button
                    href={button.href}
                    openInNewTab={button.openInNewTab}
                    variant={ButtonVariant.SecondaryInverted}
                    className={styles.button}
                  >
                    {button.label}
                  </Button>
                )}
              </div>

              <div className={styles.imageGrid}>
                <div className={styles['item-a']}>{renderItem(items[0])}</div>
                <div className={styles['item-b']}>{renderItem(items[1])}</div>
                <div className={styles['item-c']}>{renderItem(items[2])}</div>
                <div className={styles['item-d']}>{renderItem(items[3])}</div>
                <div className={styles['item-e']}>{renderItem(items[4])}</div>
                <div className={styles['item-f']}>{renderItem(items[5])}</div>
              </div>
            </div>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </Section>
  );
}
