'use client';
import { useEffect, useState } from 'react';
import { useIntl } from 'src/domains/i18n';
import {
  GeolocationError,
  useGeolocation,
} from 'src/features/shared/hooks/use-geo-location';
import { Club } from 'src/features/shared/types/Club';
import { getClubsByGeolocation } from '../util/get-clubs-by-geolocation';

interface ClubsNearbyProps<T> {
  enabled: boolean;
  clubs: T[];
}

/**
 * This hook returns the clubs order by distance of the user's location.
 */
export function useClubsNearby<T extends Club.Electrolyte>({
  clubs,
  enabled,
}: ClubsNearbyProps<T>) {
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<T[]>([]);
  const { formatMessage } = useIntl();
  const {
    error,
    isLoading: locationIsLoading,
    latitude,
    longitude,
  } = useGeolocation({}, enabled);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    setIsLoading(true);

    if (error) {
      setResults([]);
      setIsLoading(false);
      return;
    }

    if (!locationIsLoading && latitude && longitude) {
      const newResults = getClubsByGeolocation(
        clubs,
        { latitude, longitude },
        null,
      );
      setResults(newResults);
      setIsLoading(false);
    }
  }, [clubs, enabled, error, locationIsLoading, latitude, longitude]);

  const getErrorMessage = () => {
    switch (error?.code) {
      case GeolocationError.PERMISSION_DENIED:
        return formatMessage('use-my-location-button.error.permission-denied');
      case GeolocationError.POSITION_UNAVAILABLE:
        return formatMessage('use-my-location-button.error.unavailable');
      case GeolocationError.TIMEOUT:
        return formatMessage('use-my-location-button.error.default');
      default:
        null;
    }
  };

  return {
    enabled,
    results,
    error,
    errorMessage: getErrorMessage(),
    isLoading: isLoading || locationIsLoading,
  };
}
