import classNames from 'classnames';
import styles from './SocialCardsItem.module.scss';
import Link from 'next/link';
import { ContentfulImage } from 'src/features/shared/components/contentful-image';
import Icons from 'src/features/shared/components/icons';
import { ISocialSectionItem } from 'src/features/shared/contentful/types/ISocialSection';

const iconMap: Record<ISocialSectionItem['icon'], SVGComponent> = {
  Instagram: Icons.Instagram,
  Facebook: Icons.Facebook,
  Tiktok: Icons.Tiktok,
};

interface Props extends ISocialSectionItem {
  className?: string;
}

export function SocialCardsItem({ link, icon, media, className }: Props) {
  const renderMedia = () => {
    if ('url' in media) {
      return (
        <video
          src={media.url}
          title={media.title}
          className={styles.media}
          height={'100%'}
          autoPlay={true}
          playsInline
          muted
          loop
        />
      );
    }
    if (media.src) {
      return (
        <ContentfulImage
          src={media.src}
          alt={media.alt}
          fill
          className={styles.media}
        />
      );
    }
  };

  const renderCard = () => {
    const Icon = iconMap[icon];
    return (
      <>
        {renderMedia()}
        <div className={styles.iconWrapper}>
          <Icon className={styles.icon} />
        </div>
      </>
    );
  };

  if (link) {
    return (
      <Link
        href={link.href}
        target={link.openInNewTab ? '_blank' : undefined}
        className={classNames(styles.card, styles.link, className)}
      >
        {renderCard()}
      </Link>
    );
  }

  return (
    <div className={classNames(styles.card, className)}>{renderCard()}</div>
  );
}
