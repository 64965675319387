import theme from '@config/theme';
import { useIntl } from '@domains/i18n';
import { Button } from '@features/shared/components/button';
import { ButtonType } from '@features/shared/types/enums/ButtonType';
import { ButtonVariant } from '@features/shared/types/enums/ButtonVariant';
import {
  DAYPASS_CHECKOUT,
  DEFAULT_CHECKOUT,
} from 'src/features/checkout/util/constants';

interface Props {
  clubId?: number;
  isDayPass?: true;
  isFullWidth?: boolean;
  isSecondary?: boolean;
  dataTestId?: string;
  className?: string;
  onClick?: () => void;
}

export const JoinButton = ({
  clubId,
  isDayPass,
  isFullWidth,
  isSecondary,
  dataTestId,
  className,
  onClick,
}: Props) => {
  const { formatMessage } = useIntl();

  const slug = isDayPass ? DAYPASS_CHECKOUT : DEFAULT_CHECKOUT;
  const basePath = theme.checkout.path;
  const params = new URLSearchParams();
  if (clubId) {
    params.set('clubId', clubId.toString());
  }

  const queryPath = params.toString();
  const href = `${isDayPass ? '/checkout' : basePath}/${slug}?${queryPath}`;

  const getButtonVariant = () => {
    if (isDayPass) {
      return ButtonVariant.Inverted;
    }

    if (isSecondary) {
      return ButtonVariant.Secondary;
    }

    return ButtonVariant.Primary;
  };

  return (
    <Button
      type={ButtonType.Button}
      isFullWidth={isFullWidth}
      variant={getButtonVariant()}
      href={href}
      openInNewTab={isDayPass}
      isHtmlTag // NOTE: remove this after replacing getInitialProps from funnel slugs
      dataTestId={dataTestId}
      onClick={onClick}
      className={className}
    >
      {isDayPass ? formatMessage('day-pass.request') : formatMessage('join')}
    </Button>
  );
};
