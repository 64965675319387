import {
  type RefObject,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';

export interface UseInViewportOptions {
  once?: boolean;
  onEnterViewport?: () => void;
  onLeaveViewport?: () => void;
}

export function useInViewport<T extends HTMLElement = HTMLElement>(
  elementRef: RefObject<T>,
  options: UseInViewportOptions = {
    once: false,
  },
) {
  const [inViewport, setInViewport] = useState(false);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const onceTriggeredRef = useRef(false);

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      const isIntersecting = entry.isIntersecting;

      if (options.once && onceTriggeredRef.current) {
        return;
      }

      if (isIntersecting) {
        setInViewport(true);
        options.onEnterViewport?.();
        if (options.once) {
          onceTriggeredRef.current = true;
          observerRef.current?.disconnect();
        }
      } else {
        setInViewport(false);
        options.onLeaveViewport?.();
      }
    },
    [options],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection);
    observerRef.current = observer;

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [elementRef, handleIntersection]);

  return inViewport;
}
