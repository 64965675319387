import styles from './Markers.module.scss';
import { DEFAULT_ZOOM, Marker } from '@features/shared/components/map/ui';
import { InfoWindow, useMap } from '@vis.gl/react-google-maps';
import { useCallback, useEffect, useMemo } from 'react';
import { IClub } from 'src/features/club-page/types/IClub';
import { Cluster } from 'src/features/shared/components/map/ui/cluster';
import { useMarkers } from '../map/hooks/use-markers';
import { BoundingBox, ICluster } from '../map/types';

interface IMapContainerProps {
  zoom: number;
  clubs: IClub[];
  selectedClubId?: number;
  bounds: BoundingBox;
  padding?: number | google.maps.Padding;
  onMarkerClick?: (clubId: number | undefined) => void;
  infoCard?: React.ReactNode;
}

export function Markers({
  zoom,
  clubs,
  selectedClubId,
  padding,
  bounds,
  onMarkerClick,
  infoCard,
}: IMapContainerProps) {
  const map = useMap();

  const markerData = useMemo(() => {
    return clubs.map((club) => {
      return {
        id: club.id,
        lat: club.latitude,
        lng: club.longitude,
      };
    });
  }, [clubs]);

  const { clusters, markers, supercluster, markersBounds } = useMarkers(
    markerData,
    {
      bounds,
      zoom,
    },
  );

  const handleClusterClick = useCallback(
    ({ id, lat, lng }: ICluster) => {
      if (!supercluster) {
        return;
      }

      const newZoom = supercluster?.getClusterExpansionZoom(id);
      map?.setZoom(newZoom);
      map?.setCenter({ lat, lng });
    },
    [supercluster, map],
  );

  const handleMarkerClick = useCallback(
    (clubId: number | undefined) => {
      onMarkerClick?.(clubId);
    },
    [onMarkerClick],
  );

  useEffect(() => {
    if (markersBounds && zoom === DEFAULT_ZOOM) {
      map?.fitBounds(markersBounds, padding);
    }
  }, [markersBounds, map, padding, zoom]);

  const markersMemoized = useMemo(() => {
    return markers.map((marker) => {
      return (
        <Marker
          key={marker.id}
          position={{
            lat: marker.lat,
            lng: marker.lng,
          }}
          selected={marker.id === selectedClubId}
          onClick={() => handleMarkerClick?.(marker.id)}
          scale={{ x: 54, y: 63 }}
          className={styles.marker}
        />
      );
    });
  }, [markers, handleMarkerClick, selectedClubId]);

  const clustersMemoized = useMemo(() => {
    return clusters.map((cluster) => {
      return (
        <Cluster
          key={cluster.id}
          position={{
            lat: cluster.lat,
            lng: cluster.lng,
          }}
          count={cluster.point_count}
          className={styles.cluster}
          onClick={() => handleClusterClick(cluster)}
        />
      );
    });
  }, [clusters, handleClusterClick]);

  const getInfoWindow = () => {
    if (!infoCard || !selectedClubId) return null;

    const marker = markerData.find((marker) => marker.id === selectedClubId);
    if (!marker) return null;
    return (
      <InfoWindow
        position={{
          lat: marker.lat,
          lng: marker.lng,
        }}
        key={marker.id}
        onCloseClick={() => handleMarkerClick?.(undefined)}
      >
        {infoCard}
      </InfoWindow>
    );
  };

  return (
    <>
      {getInfoWindow()}
      {markersMemoized}
      {clustersMemoized}
    </>
  );
}
