import styles from './ClubsOverviewMap.module.scss';
import { Layout } from 'features/shared/components/layout';
import { useState } from 'react';
import { useIntl } from 'src/domains/i18n';
import { ClubCard } from 'src/features/clubs-map-page/components/club-card';
import { ClubsMap } from 'src/features/shared/components/clubs-map';
import { MobileInfoCard } from 'src/features/shared/components/sections/club-list/club-overview-map/mobile-info-card/MobileInfoCard';
import { Club } from 'src/features/shared/types/Club';
import { IClubWithGeoDistance } from 'src/features/shared/types/IClubWithGeoDistance';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import useBreakpoints from 'src/utils/hooks/use-breakpoints';

interface ClubsOverviewMapProps {
  clubs: Club.MapCard[];
  geolocation?: {
    lat: number;
    lng: number;
  };

  markers: IClubWithGeoDistance[];
  onEnableUserLocation: () => void;
}

export function ClubsOverviewMap({
  clubs,
  geolocation,
  markers,
  onEnableUserLocation,
}: ClubsOverviewMapProps) {
  const [selectedClubId, setSelectedClubId] = useState<number | undefined>();
  const { formatMessage } = useIntl();
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const isMobile = currentBreakpoint === breakpoints.xs.name;

  const selectedClub: Club.MapCard | undefined = clubs.find(
    (club) => club.pgClubId === selectedClubId,
  );

  const onMarkerClick = (clubId: number | undefined) => {
    setSelectedClubId(clubId);
    pushGA4Event(GA4_EVENTS.trackEvent, { event_name: 'click_club_map' });
  };

  const getInfoCard = () => {
    if (!selectedClub) return null;
    return (
      <ClubCard
        club={selectedClub}
        className={styles.card}
        primaryButtonStyle="primary"
        primaryButtonLabel={formatMessage('become-member')}
      />
    );
  };

  return (
    <Layout.Row reversed>
      <Layout.Col columns="xs:12" className={styles.col}>
        {markers && Boolean(markers.length) && (
          <ClubsMap
            clubs={markers}
            userLocation={geolocation ?? undefined}
            onEnableUserLocation={onEnableUserLocation}
            onMarkerClick={onMarkerClick}
            className={styles.map}
            selectedClubId={selectedClubId}
            infoCard={isMobile ? undefined : getInfoCard()}
          />
        )}
        {isMobile && selectedClub && (
          <MobileInfoCard
            onCloseClick={() => setSelectedClubId(undefined)}
            club={selectedClub}
          />
        )}
      </Layout.Col>
    </Layout.Row>
  );
}

export default ClubsOverviewMap;
